<script setup>
import {computed, onMounted, ref, watch, watchEffect} from "vue";
import { useAppProductRequest } from '@/stores/appProductRequest';
import axios from "axios";

const store = useAppProductRequest();

const typeList = [
  {key: 'EXCHANGE', label: '교환'},
  {key: 'PAYBACK', label: '반품'},
  {key: 'CANCEL', label: '취소'},
];

const returnType = [
  {key: 'MIND_CHANGE', label: '단순 변심'},
  {key: 'MISTAKE', label: '주문 실수'},
  {key: 'DAMAGE', label: '파손 및 불량'},
  {key: 'MISDELIVERY', label: '오배송 및 지연'},
  {key: 'OPTION_CHANGE', label: '옵션 변경'},
]

/*
const form = ref({
  orderId: "",
  type: "",
  returnType: "",
  goodsAll: false,
  goods: [],
  title: null,
  reason: null,
});
*/

const form = ref({
  oid: store.requestOID,
  orderId: store.requestOrderId,
  refundType: "",
  reasonType: "",
  refundAll: false,
  refundItemList: [],
  title: null,
  reasonDetail: null,
  refundAccountNumber: "",
  refundAccountOwner: "",
  refundAccountBankName: "",
});
const refundAccountUse = ref(false);
const images = ref([]);

watchEffect(() => {
  if (form.value.goodsAll) {
    console.log(store.deliveryList);
    // form.value.goods = store.orderProductList.map((item) => item.id);
  }
});


watch(form.value.goodsAll, (value) => {
  console.log(value);
});

computed(() => {
  console.log(form.value);
});


const submitForm = async () => {
  //분류 유효성 검사
  const refundType = form.value.refundType;
  if (!refundType) {
    alert('분류를 선택해 주세요.');
    return false;
  }
  //상품 유효성 검사
  const refundItemList = form.value.refundItemList;
  if (refundItemList.length === 0 && form.value.refundAll === false) {
    alert('상품을 선택해 주세요.');
    return false;
  }
  //제목 유효성 검사
  const title = form.value.title;
  if (!title) {
    alert('제목을 입력해 주세요.');
    return false;
  }
  //사유 유효성 검사
  const reasonDetail = form.value.reasonDetail;
  if (!reasonDetail) {
    alert('사유를 입력해 주세요.');
    return false;
  }

  const refundItems = [];
  form.value.refundItemList.forEach((item) => {
    refundItems.push({
      orderProductId: item,
      fullRefunded: false
    });
  });
  form.value.refundItemList = refundItems;

  console.log(form.value);


  // images
  const formData = new FormData();
  for (let i = 0; i < images.value.length; i++) {
    formData.append('images', images.value[i]);
  }
  // formData.append('json', JSON.stringify(form.value));
  // formData.append('json', JSON.stringify(form.value));
  formData.append("json", [JSON.stringify(form.value)]);
  // TODO: B003
  const {data, status} = await axios.post('/new_api/customer/refund/new', formData);
  if (status === 200) {
    if (data.code === "200") {
      alert('신청이 완료되었습니다. 확인 후 처리 됩니다.');
      cancelForm();
      return true;
    }
    else if(data.code === "032"){
      alert("입금 대기 상태에서는 취소/반품/교환 신청이 불가능합니다.");
      return false;
    }
    else {
      alert(data.message);
      return false;
    }
  }
  else {
    alert('잠시 후 다시 시도 하여 주세요.');
    return false;
  }
}

const selectImageFiles = (e) => {
  const files = e.target.files;
  if (files.length > 0) {
    images.value = files;
  }
}

const resetForm = () => {
  form.value = {
    orderId: null,
    refundType: "",
    reasonType: "",
    refundAll: false,
    refundItemList: [],
    title: null,
    reasonDetail: null
  };
}
const cancelForm = () => {
  store.setSwitchProductRequest(false);
  resetForm();
}

</script>

<template>

  <div class="modal_container">

    <div class="max-w-[600px] w-[600px] mx-auto p-6 bg-white rounded-md shadow-md">
      <h2 class="text-2xl font-bold mb-4">상품 교환, 반품, 취소</h2>
      <div>
        <div class="mb-4 flex gap-2">
          <select class="form_style" v-model="form.refundType">
            <option value="" selected>분류선택</option>
            <option :value="type.key" v-for="type in typeList"> {{ type.label }} </option>
          </select>

          <select class="form_style" v-model="form.reasonType">
            <option value="" selected>분류선택</option>
            <option :value="type.key" v-for="type in returnType"> {{ type.label }} </option>
          </select>
        </div>
        <div class="mb-4">
          <div class="flex gap-3">
            <label for="product" class="block text-sm font-semibold mb-2">
              상품 선택
            </label>
            <label for="all">
              <input type="checkbox" id="all" v-model="form.refundAll">
              전체
            </label>
          </div>
          <div class="form_style max-h-[200px] overflow-y-auto">
            <div v-for="(warehouse, wareKey) in store.deliveryList">
              <p class="font-bold"> {{ warehouse.brandName }} </p>
              <ul>
                <li v-for="(item, itemKey) in warehouse.orderProductList" class="orderProductList">
                  <div>
                    <label :for="'product_'+wareKey+itemKey">
                      <input type="checkbox" :id="'product_'+wareKey+itemKey" :value="item.orderItemId" v-model="form.refundItemList">
                      {{ item.orderItemTitle }}
                    </label>
                  </div>
                  <div class="ml-5 text-sm text-gray-300">
                    <ul>
                      <li v-for="option in item.optionList" class="productOptionList">
                        <div>
                          <span v-for=" ( optionItem, pathIndex ) in option.infoDetailList ">
                            <span> {{ optionItem.optionName }} > {{ optionItem.elementName }} </span>
                          </span>
                        </div>
                        <span class="px-2 text-sm text-gray-300"> / </span>
                        <div> {{ option.quantity }} </div>
                        <span class="px-2 text-sm text-gray-300"> / </span>
                        <div> {{ option.totalOptionPrice }}원 </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <label for="title" class="block text-sm font-semibold mb-2">제목</label>
          <input type="text" name="title" id="title" class="form_style" v-model="form.title" placeholder="제목을 입력해 주세요.">
        </div>

        <div class="mb-4">
          <label for="reason" class="block text-sm font-semibold mb-2">사유</label>
          <textarea name="reason" id="reason" rows="4" class="form_style" v-model="form.reasonDetail" placeholder="사유를 입력해 주세요."></textarea>
        </div>

        <div class="mb-4">
          <label for="account" class="block text-sm font-semibold mb-2">
            <input type="checkbox" id="account" v-model="refundAccountUse"> 환불 계좌 입력
          </label>
          <div v-if="refundAccountUse===true">
            <div class="flex gap-3">
              <input type="text" class="form_style" v-model="form.refundAccountBankName" placeholder="은행명">
              <input type="text" class="form_style" v-model="form.refundAccountNumber" placeholder="계좌번호">
              <input type="text" class="form_style" v-model="form.refundAccountOwner" placeholder="예금주">
            </div>
          </div>
        </div>

        <div class="mb-4">
          <label class="block text-sm font-semibold mb-2">파일첨부</label>
          <input type="file" name="file" class="form_style" placeholder="파일을 첨부해 주세요." @change="selectImageFiles">
        </div>
        <div class="mb-4 flex justify-center gap-3">
          <button type="button" class="cancel_btn" @click="cancelForm"> 취소 </button>
          <button type="submit" class="submit_btn" @click="submitForm"> 신청합니다 </button>
        </div>
      </div>
    </div>


  </div>

</template>

<style scoped>
.form_style {
  @apply w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500;
}
.submit_btn {
  @apply bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200;
}
.cancel_btn {
  @apply border py-2 px-4 rounded-md hover:bg-gray-300 transition duration-200;
}
.modal_container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.reviewWrite_wrapper {
  width: 88%;
  max-width: 36rem;
  min-height: 88vh;
  background-color: #fefefe;
  padding: 1rem;
  border: 1px solid #888;
  max-height: 90vh;
  overflow-y: scroll;
}

.close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.productOptionList {
  display: flex;
  align-items: baseline;
}

.optionBody {
  overflow-y: auto;
  max-height: 70vh;
}
.optionFooter {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.selectClass {
  @apply border border-neutral-800 p-2 col-span-4 w-full;
}
.countBox {
  @apply py-2 px-3 inline-block bg-white rounded-lg dark:bg-slate-900 dark:border-gray-700;
}

.orderProductList {
  border-bottom: 1px solid #ccc;
}
</style>

