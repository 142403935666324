export const optionsMap = {
  groupName: [
    { label: "영유아부", value: "INFANT" },
    { label: "유치부", value: "KINDERGARTEN" },
    { label: "유년부", value: "CHILD" },
    { label: "초등부", value: "ELEMENTARY" },
    { label: "청소년부", value: "TEENAGER" },
    { label: "대학청년부", value: "ADULT" },
    { label: "장년부", value: "SENIOR" },
    { label: "사역자", value: "MINISTER" },
  ],
  contentType: [
    { label: "이미지", value: "IMAGE" },
    { label: "파일", value: "FILE" },
    { label: "영상", value: "VIDEO" },
    { label: "음원", value: "MUSIC" },
  ],
  bibleBookName: [
    // 구약
    { label: "창세기", value: "창세기" },
    { label: "출애굽기", value: "출애굽기" },
    { label: "레위기", value: "레위기" },
    { label: "민수기", value: "민수기" },
    { label: "신명기", value: "신명기" },
    { label: "여호수아", value: "여호수아" },
    { label: "사사기", value: "사사기" },
    { label: "룻기", value: "룻기" },
    { label: "사무엘상", value: "사무엘상" },
    { label: "사무엘하", value: "사무엘하" },
    { label: "열왕기상", value: "열왕기상" },
    { label: "열왕기하", value: "열왕기하" },
    { label: "역대상", value: "역대상" },
    { label: "역대하", value: "역대하" },
    { label: "에스라", value: "에스라" },
    { label: "느헤미야", value: "느헤미야" },
    { label: "에스더", value: "에스더" },
    { label: "욥기", value: "욥기" },
    { label: "시편", value: "시편" },
    { label: "잠언", value: "잠언" },
    { label: "전도서", value: "전도서" },
    { label: "아가", value: "아가" },
    { label: "이사야", value: "이사야" },
    { label: "예레미야", value: "예레미야" },
    { label: "예레미야애가", value: "예레미야애가" },
    { label: "에스겔", value: "에스겔" },
    { label: "다니엘", value: "다니엘" },
    { label: "호세아", value: "호세아" },
    { label: "요엘", value: "요엘" },
    { label: "아모스", value: "아모스" },
    { label: "오바댜", value: "오바댜" },
    { label: "요나", value: "요나" },
    { label: "미가", value: "미가" },
    { label: "나훔", value: "나훔" },
    { label: "하박국", value: "하박국" },
    { label: "스바냐", value: "스바냐" },
    { label: "학개", value: "학개" },
    { label: "스가랴", value: "스가랴" },
    { label: "말라기", value: "말라기" },

    // 신약
    { label: "마태복음", value: "마태복음" },
    { label: "마가복음", value: "마가복음" },
    { label: "누가복음", value: "누가복음" },
    { label: "요한복음", value: "요한복음" },
    { label: "사도행전", value: "사도행전" },
    { label: "로마서", value: "로마서" },
    { label: "고린도전서", value: "고린도전서" },
    { label: "고린도후서", value: "고린도후서" },
    { label: "갈라디아서", value: "갈라디아서" },
    { label: "에베소서", value: "에베소서" },
    { label: "빌립보서", value: "빌립보서" },
    { label: "골로새서", value: "골로새서" },
    { label: "데살로니가전서", value: "데살로니가전서" },
    { label: "데살로니가후서", value: "데살로니가후서" },
    { label: "디모데전서", value: "디모데전서" },
    { label: "디모데후서", value: "디모데후서" },
    { label: "디도서", value: "디도서" },
    { label: "빌레몬서", value: "빌레몬서" },
    { label: "히브리서", value: "히브리서" },
    { label: "야고보서", value: "야고보서" },
    { label: "베드로전서", value: "베드로전서" },
    { label: "베드로후서", value: "베드로후서" },
    { label: "요한일서", value: "요한일서" },
    { label: "요한이서", value: "요한이서" },
    { label: "요한삼서", value: "요한삼서" },
    { label: "유다서", value: "유다서" },
    { label: "요한계시록", value: "요한계시록" },
  ],
  hanaDivision: [
    { label: "성경스토리", value: "성경스토리" },
    { label: "공과강의", value: "공과강의" },
    { label: "찬양,챈트", value: "찬양,챈트" },
    { label: "오감활동", value: "오감활동" },
    { label: "예배영상", value: "예배영상" },
    { label: "교육콘텐츠", value: "교육콘텐츠" },
    { label: "공과시연", value: "공과시연" },
    { label: "선행학습", value: "선행학습" },
    { label: "미션북", value: "미션북" },
    { label: "오감 활동", value: "오감 활동" },
    { label: "성경 스토리", value: "성경 스토리" },
    { label: "단원챈트", value: "단원챈트" },
    { label: "단원찬양", value: "단원찬양" },
    { label: "나무를보다", value: "나무를보다" },
    { label: "교회용 공과시연 영상", value: "교회용 공과시연 영상" },
    { label: "교회용 공과시연", value: "교회용 공과시연" },
    { label: "교사교육", value: "교사교육" },
    { label: "가정용 공과시연 영상", value: "가정용 공과시연 영상" },
    { label: "가정예배", value: "가정예배" },
    { label: "기타", value: "기타" },
    { label: "학습요점", value: "학습요점" },
    { label: "단원송 챈트", value: "단원송 챈트" },
    { label: "미션북정답", value: "미션북정답" },
    { label: "단원영상", value: "단원영상" },
    { label: "찬양,암송곡", value: "찬양,암송곡" },
    { label: "스토리텔링", value: "스토리텔링" },
    { label: "길을 걷다", value: "길을 걷다" },
    { label: "사운드", value: "사운드" },
    { label: "부서", value: "부서" },
  ],
};
