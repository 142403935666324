<template>
  <!-- class="lg:flex flex-1" -->
  <div>
    <TNavComponent/>
    <toTopComponent/>    
    <component :is="currentComponent" class="max-w-[1200px] mx-auto py-20"/>
    <FooterComponent/>
  </div>
</template>

<script setup>
import TNavComponent from "@/components/TNavComponent.vue"
import toTopComponent from "@/components/toTopComponent.vue"
import FooterComponent from "@/components/FooterComponent.vue";
import PolicyInfoPage from "@/pages/PolicyInfoPage.vue"
import PolicyGuidePage from "@/pages/PolicyGuidePage.vue";
import { shallowRef, watchEffect } from 'vue'
import { useRoute } from 'vue-router'


const currentComponent = shallowRef(PolicyInfoPage)

const route = useRoute()

watchEffect(() => {
  if (route.name === "PolicyInfo") {
    currentComponent.value = PolicyInfoPage;
  } else {
    currentComponent.value = PolicyGuidePage;
  }
})
</script>
