<template>
  <main class="max-w-[1200px] mx-auto mb-20 px-5 flex-1">
    <h1 class="font-bold text-center my-8">내 콘텐츠</h1>
    
    <div class="mt-10 max-lg:px-5" v-if="Object.keys(myContents).length > 0">
      <div class="flex items-center gap-3 pb-8 border-b border-gray-200 max-lg:flex-col">
        <div class="product_thumb !w-40">
          <img
          v-if="myContents.thumbnailLink"
          :src="'https://eduwind-api.nodeblack.net/' + myContents.thumbnailLink"
          :alt="myContents.orderContentTitle"
        />
        <img
          v-else
          src="@/assets/img/null_img.jpg"
          :alt="myContents.orderContentTitle"
        />
        </div>
        <div>
          <h4 class="mb-2">{{ myContents.orderContentTitle }}</h4>
          <div v-if="new Date(myContents.downloadExpiration) < base">무제한</div>
          <div v-else
          :class="{'text-red-500': isExpired(myContents.downloadExpiration)}">
            <p>다운로드 기한</p>
            {{ formDownloadDate(myContents.downloadExpiration) }} 까지
            <p v-if="isExpired(myContents.downloadExpiration)" class="text-red-500 font-semibold">기한만료</p>
          </div>
        </div>
        
      </div>
      
      <div
        v-if="myContents.orderContentStatus == 'DOWNLOAD_DOING' || myContents.orderContentStatus == 'CREATE_DONE'"
        class="py-8">

        <!-- 컨텐츠, 음원일 경우 -->
        <div v-if="myContents.contentType == 'FILE' || myContents.contentType == 'MUSIC' || myContents.contentType == 'IMAGE'" class="flex flex-col mb-20">
          <button v-if="myContents.orderDownloadInfoList.length > 1" class="flex items-center w-fit place-self-end gap-2 p-2 border border-gray-200 rounded mb-4" @click="downloadZIPContent">
            <b class="text-sm">모두 다운로드</b>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.46154 17H2.23077C1.90435 17 1.5913 16.8703 1.36048 16.6395C1.12967 16.4087 1 16.0956 1 15.7692V5.92303H17V15.7692C17 16.0956 16.8703 16.4087 16.6395 16.6395C16.4087 16.8703 16.0957 17 15.7692 17H14.5385" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 1V5.92308M14.28 1.61538C14.1747 1.43291 14.0244 1.2805 13.8434 1.17275C13.6624 1.06501 13.4567 1.00552 13.2462 1H4.75385C4.54326 1.00552 4.33762 1.06501 4.1566 1.17275C3.97559 1.2805 3.82526 1.43291 3.72 1.61538L1 5.92308H17L14.28 1.61538Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 13.0833L9 16M9 16L12 13.0833M9 16V9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
  
          <div class="flex flex-wrap gap-2">
            <div 
              class="w-fit flex items-center gap-2 p-2 mb-2 border rounded cursor-pointer"
              v-for="(content, idx) in myContents.orderDownloadInfoList"
              :key="content.orderDownloadId"
              @click="downloadContent(content.orderDownloadId)"
            >
              <div>{{idx + 1}}.</div>
              <div class=" text-blue-600 underline underline-offset-1">{{content.orderDownloadName}}</div>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 12.6667V16.2222C18 16.6937 17.8127 17.1459 17.4793 17.4793C17.1459 17.8127 16.6937 18 16.2222 18H3.77778C3.30628 18 2.8541 17.8127 2.5207 17.4793C2.1873 17.1459 2 16.6937 2 16.2222V12.6667M5.55556 8.22222L10 12.6667M10 12.6667L14.4444 8.22222M10 12.6667V2" stroke="blue" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>  
            </div>
          </div>
        </div>
        
        <!-- 영상인 경우 -->
        <div v-else-if="myContents.contentType == 'VIDEO'" class="mb-20">
          <div v-for="vid in myContents.orderVideoInfoList" :key="vid.orderVideoId"
           class="mb-4 p-4 border border-gray-200">
            <h4 class="mb-2">{{vid.orderVideoName}}</h4>
            <div class="flex gap-2 max-lg:flex-col">
              <a v-if="vid.linkNormal" :href="vid.linkNormal" target="_blank" class="flex justify-center items-center gap-1 lg:w-fit px-4 py-2 sec_btn_black">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.6667 16.6667H2.08333V2.08333H16.6667M16.6667 0H2.08333C1.5308 0 1.00089 0.219493 0.610194 0.610194C0.219493 1.00089 0 1.5308 0 2.08333V16.6667C0 17.2192 0.219493 17.7491 0.610194 18.1398C1.00089 18.5305 1.5308 18.75 2.08333 18.75H16.6667C17.2192 18.75 17.7491 18.5305 18.1398 18.1398C18.5305 17.7491 18.75 17.2192 18.75 16.6667V2.08333C18.75 1.5308 18.5305 1.00089 18.1398 0.610194C17.7491 0.219493 17.2192 0 16.6667 0ZM7.29167 5.20833V13.5417L12.5 9.375L7.29167 5.20833Z" fill="black"/>
                </svg> 
                일반화질 보기
                <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.68691 3.72025L2.15601 10.2512L1.44889 9.54403L7.97979 3.01313L8.82073 2.17219L7.63153 2.1596L2.24871 2.10261L2.24871 1.1513L10.55 1.15003L10.5487 9.45133L9.59743 9.45133L9.54044 4.06851L9.52785 2.87931L8.68691 3.72025Z" fill="black" stroke="black"/>
                </svg>
              </a>
              <a v-if="vid.linkHigh" :href="vid.linkHigh" target="_blank" class="flex justify-center items-center gap-1 lg:w-fit px-4 py-2 pri_btn_bg">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.7917 0.125H2.20833C1.05208 0.125 0.125 1.05208 0.125 2.20833V16.7917C0.125 17.9375 1.0625 18.875 2.20833 18.875H16.7917C17.9375 18.875 18.875 17.9375 18.875 16.7917V2.20833C18.875 1.6558 18.6555 1.12589 18.2648 0.735194C17.8741 0.344493 17.3442 0.125 16.7917 0.125ZM7.41667 13.6667V5.33333L12.625 9.5" fill="#F8F8F8"/>
                </svg>  
                고화질 보기
                <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.68691 3.72025L2.15601 10.2512L1.44889 9.54403L7.97979 3.01313L8.82073 2.17219L7.63153 2.1596L2.24871 2.10261L2.24871 1.1513L10.55 1.15003L10.5487 9.45133L9.59743 9.45133L9.54044 4.06851L9.52785 2.87931L8.68691 3.72025Z" fill="white" stroke="white"/>
                </svg>
              </a>
            </div>
          </div>
        </div>

        <h4 class="my-2">이용안내</h4>
        <div class="p-5 border-neutral-200 border rounded-lg">
          <p class="font-medium">사용허가정보</p>
          <p>
            - 본 콘텐츠의 저작권 및 책임은 해당 브랜드에게 있고, 총회교육.com은 위탁하여 관리합니다. <br>
            - 설교콘텐츠의 재편집은 가능하나 제3자에게 배포, 판매, 양도, 대여할 수 없습니다. <br>
            - 구매한 콘텐츠 원본은 SNS포함 모든 온라인에 배포할 수 없습니다.  <br>
            - 영상 콘텐츠로의 편집 후 유투브등의 영상채널에 배포시 추가 라이센스 획득 후 사용하셔야 합니다.<br>
            - 웹 및 온라인 배포, 상업적 용도, 인쇄물 제작시에는 별도 확장 라이센스 획득이 필요합니다.<br><br>
          </p>

          <p class="font-medium">환불안내</p>
          <p>
            - 콘텐츠 다운로드시, 원하는 콘텐츠 인지 꼭 한번 더 확인을 부탁드립니다.<br>
            - 결제 및 다운로드 후 1개월 이내에는 횟수 제한 없이 재다운로드가 가능합니다.<br>
            - 결제 및 다운로드 후 1개월 이내에 콘텐츠 자체의 오류나 이상등이 발견되는 경우 환불 조치가 가능하며,<br>환불대신 새파일을 받기 요청하시면 새파일 다운로드 가능합니다. <br><br>

          콘텐츠제작관련과 기타 콘텐츠에 대한 문의사항은 문의게시판에 남겨주시면 답변 드리겠습니다.
          </p>


        </div>
      </div>

      <div v-else class="my-8 py-8 flex flex-col justify-center items-center gap-2">
        <svg width="56" height="49" viewBox="0 0 56 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27.8771 2.72916L2.66872 46.2708H53.0854L27.8771 2.72916Z" stroke="black" stroke-width="4.58333" stroke-linejoin="round"/>
          <path d="M27.877 37.1041V38.25M27.877 18.7708L27.8862 30.2291" stroke="black" stroke-width="4.58333" stroke-linecap="round"/>
        </svg>
        <h3>이용이 불가합니다.</h3>
        <p></p>
      </div>

      <router-link to="/mypage/contentsBox"
        class="w-fit flex items-center gap-4 mt-10 px-6 py-3 mx-auto bg-[#0C3388] border-[#0C3388] text-white">          
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
          <path d="M9.5 1.5L1.5 9.5L9.5 17.5" stroke="white" stroke-width="2"/>
          <path d="M19.5 9.5H1.5" stroke="white" stroke-width="2"/>
        </svg>
        목록으로
      </router-link>
    </div>
  </main>
</template>

<script setup>
import axios from 'axios';
import { onMounted, ref } from "vue";
import { useRoute } from 'vue-router';
const route = useRoute();

const orderContentId = route.params.ocid;

const myContents = ref({});

// 컨텐츠 상세 GET
const getOrderContentDetail = async () => {
  try {
    const res = await axios.get('/new_api/customer/order/content/one/' + orderContentId);
    myContents.value = res.data.data;
    
  } catch (error) {
    console.error(error);
  }
};

onMounted(async () => {
  await getOrderContentDetail();
});

// 컨텐츠 개별 다운로드
const downloadContent = async (orderDownloadId) => {
  try {
    const res = await axios.get(`/new_api/customer/order/content/download/one?orderContentId=${orderContentId}&orderDownloadId=${orderDownloadId}`, {
      responseType: 'blob',
    });

    // 다운로드 생성
    const blob = new Blob([res.data], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);

    const downloadLink = Object.assign(document.createElement('a'), {
      href: url,
      download: decodeURIComponent(
        res.headers['content-disposition']
        .match(/filename\*?=(UTF-8[''].*'')?([^;]+)/)[2]
        .trim()
        .replace(/^"=\?UTF-8\?Q\?/, '')
        .replace(/\?="$/, '')
      )
    });

    // 임시링크 생성 후 다운로드 진행
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);

  } catch (error) {
    console.error(error);
  }
}

const downloadZIPContent = async () => {
  orderContentId
  try {
    const res = await axios.get(`/new_api/customer/order/content/download/zip?orderContentId=${orderContentId}`, {
      responseType: 'blob',
    });

    // 다운로드 생성
    const blob = new Blob([res.data], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);

    const downloadLink = Object.assign(document.createElement('a'), {
      href: url,
      download: decodeURIComponent(
        res.headers['content-disposition']
        .match(/filename\*?=(UTF-8[''].*'')?([^;]+)/)[2]
        .trim()
        .replace(/^"=\?UTF-8\?Q\?/, '')
        .replace(/\?="$/, '')
      )
    });

    // 임시링크 생성 후 다운로드 진행
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);

  } catch (error) {
    console.error(error);
  }
}

// 다운로드 기한 만료 체크
const today = new Date();
const base = new Date('2000-01-01');

const isExpired = (date) => {
  const expirationDate = new Date(date);
  return expirationDate < today;
}

const formDownloadDate = (d) => {
const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  const date = new Date(d);
  return date.toLocaleDateString('ko-KR', options).replace(/(\d+)\D(\d+)\D(\d+)\D(\d+)\D(\d+)/, '$1.$2.$3 $4:$5:$6');
};

</script>