<script setup>
import {defineEmits} from "vue";

const emit = defineEmits();

const closeModal = () => {
  emit('close');
};

const { it_id } = defineProps(['it_id']);
</script>

<template>

  <div class="modal_container">
    <div class="reviewWrite_wrapper">
      <div class="flex justify-between items-center p-2 border-b border-black">
        <h2>영수증 확인</h2>
        <button class="close" @click="closeModal">X</button>
      </div>

      <div class="p-2">
        <iframe id="inicisIframe" :src="'https://iniweb.inicis.com/receipt/iniReceipt.jsp?noTid='+it_id" width="100%" height="100%" frameborder="0" scrolling="yes"></iframe>
      </div>
    </div>
  </div>



</template>

<style scoped>
  .modal_container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .reviewWrite_wrapper {
    width: 88%;
    max-width: 46rem;
    min-height: 88vh;
    background-color: #fefefe;
    padding: 1rem;
    border: 1px solid #888;
    max-height: 90vh;
    overflow-y: scroll;
  }

  .close {
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  #inicisIframe {
    height: 80vh;
  }
</style>
